import LoginProcess from '~/config/Constants/LoginProcess';

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (typeof window !== 'undefined') {
        const {authStore, verifyToken } = useAuth();

        const {data, error} = await verifyToken();

        // If token is invalid, logout and redirect to login
        if (error.value || !data.value?.data?.is_valid) {
            authStore.logout();
            return navigateTo(LoginProcess.LOGIN_PAGE, { replace: false });
        }
    }
});
